.statistic {
	background-color: #f5f5f5;
	&__time {
		padding: 12px 36px;
		display: flex;
		gap: 25px;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		max-width: 340px;
		p {
			margin-bottom: 0;
		}
	}
	&__card--group {
		margin: 20px 0;
		display: flex;
		justify-content: center;
		// gap: 10%;
		justify-content: space-between;
	}
	&__table {
		background: #ffff;
		::-webkit-scrollbar {
			width: 10px;
		}

		/* Track */
		::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px rgb(226, 226, 226);
			border-radius: 5px;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: rgb(197, 196, 196);
			border-radius: 5px;
		}
	}
}
