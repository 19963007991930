.ant-modal {
	.ant-modal-close{
		border-radius: 0;
	}
}

.modal-form-submit {
	margin: 0;
	//display: flex;
	//justify-content: space-between;
	gap: 10px;
}

.btn-send {
	display: flex;
	justify-content: space-between;

}
.require-text {
	font-style: italic;
	margin-top: 15px;
	font-size: 12px;
}

.red-star{
	color: red;
}

.ant-upload-list-picture-card-container .ant-tooltip {
	display: none;
}
