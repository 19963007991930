.modal-content-input {
	.ant-form-item {
		flex-direction: row;
		.ant-form-item-control {
			width: 40%;
		}
	}
}
.modal-content-form {
	.ant-picker {
		display: flex !important;
	}
}
.title-upload {
	margin-bottom: 10px;
}
.skeleton-img {
	margin-bottom: 10px;
	display: flex;
	flex-flow: row wrap;
	.wrapper-img {
		position: relative;
		padding: 8px;
		margin-right: 10px;
		margin-bottom: 10px;
		border: 1px solid green;
		width: fit-content;

		img {
			width: 86px;
			height: 76px;
		}
	}
}
.ant-upload-list-picture-card .ant-upload-list-item-error {
	border-color: orange;
}
