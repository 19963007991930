.custom-tabs {
	height: 100%;
	.ant-tabs-content-holder {
		flex: 1;
		overflow-y: auto;
	}

	.ant-tabs-extra-content {
		.assign-me {
			padding: 0 0 12px 0px;
			margin-right: 0px !important;
		}
	}

	.ant-tabs-extra-content {
		.ant-input-search.ant-input-search-with-button {
			display: block;
		}
	}
	.ant-tabs-nav-operations {
		display: none;
	}
}

.ant-form-inline .ant-form-item {
	margin-right: 12px;
	&.search-btn {
		margin-right: 0;
	}
}
