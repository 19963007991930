.upload-success {


	background: #F6FFED !important;

	.ant-upload-text {
		color: #52C41A !important;
	}
}

.upload-success.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
	border-color: #52C41A !important;
}

.upload-error {
	background: #ffeded !important;

	.ant-upload-text {
		color: #c41a1a !important;
	}
}

.upload-error.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
	border-color: #c41a1a !important;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
	margin-bottom: 8px !important;
}


.modal-input {
	text-align: center;
}

button.reset-pin {
	float: left;
	padding: 4px 15px;
	border-width: 1px;
	border-color: #f18a0c;
	color: #f18a0c;
}

button.reset-pin:hover {
	color: white;
	background-color: orange;
}
