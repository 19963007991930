.actions {
	.ant-btn-link[disabled] {
		color: rgba(241, 136, 5, 1);
		opacity: 0.4;
		:hover {
			color: rgb(241, 136, 5);
		}
		:focus {
			color: rgb(241, 136, 5);
		}
		:active {
			color: rgb(241, 136, 5);
		}
	}
	.ant-btn-link {
		color: rgba(241, 136, 5, 1);
		:hover {
			color: rgb(241, 136, 5);
		}
		:focus {
			color: rgb(241, 136, 5);
		}
		:active {
			color: rgb(241, 136, 5);
		}
	}
	.ant-btn {
		:focus {
			color: rgb(241, 136, 5);
		}
	}
	.ant-btn-dangerous.ant-btn-link {
		color: rgb(241, 136, 5);
	}
	.ant-btn-dangerous.ant-btn-link[disabled] {
		color: rgb(241, 136, 5);
	}
}
