@import '../../assets/scss/color';

.e-table {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: $white;
	padding:  1rem;
	&__fullscreen {
		height: 100%;
	}
	&__header {
		display: flex;
		align-items: center;
		padding-bottom: 0.5rem;
	}

	&__util-bar {
		margin-left: auto;
	}
	.ant-table-sticky-scroll {
		display: none;
	}
}
