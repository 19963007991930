.mode-config {
	font-size: 18px;
	font-weight: 650;
	margin-bottom: 20px;
}

.leave {
	padding: 26px;
	background-color: #ffffff;
	margin: 20px 0;
	.on-leave {
		display: flex;

		justify-content: space-between;
		&__form {
			display: flex;
			justify-content: space-between;
		}
		&__input {
			width: 50px;
			margin-right: 60px;
			padding: 4px;
			text-align: center;
		}
	}
}

.holidays {
	flex-grow: 1;
	& .ant-btn .ant-btn-primary {
		margin-left: 60px;
	}
	&-filter {
		display: flex;

		justify-content: space-between;
		&__form {
			display: flex;
		}
	}
}
.holiday-actions {
	&__btn {
		margin-right: 20px;
	}
}

.ant-form-inline .ant-form-item {
	margin-right: 15px;
}
