@import '../../../../../assets/scss/color';

.eye-menu {
	display: flex;
	flex-direction: column;
	background-color: $white;
	border-radius: 10px;
	min-width: 14rem;
	padding: 1rem;
	border: 1px solid #f1f1f1;
	box-shadow: 0 0 5px darken($color: $white, $amount: 15%), 0 0 10px darken($color: $white, $amount: 10%),
		0 0 20px darken($color: $white, $amount: 5%);
	button[type='submit'] {
		align-self: flex-end;
	}
	&__row {
		display: flex;
		align-items: center;
	}
	&__label {
		margin-left: 0.5rem;
		cursor: pointer;
		flex: 1;
	}
}
