.form-image {
	position: relative;
	display: inline-block;
	border-radius: 10px;
	// overflow: hidden;
	// img {
	// 	width: 100px;
	// 	aspect-ratio: 1;
	// 	object-fit: cover;
	// }
	.icon {
		// line-height: 14px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		.icon-item {
			color: #474747;
			padding: 6px;
			background-color: rgb(255 255 255 / 50%);
			border-radius: 50%;
			cursor: pointer;
			margin-right: 8px;
		}
	}
}
.container-images {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	align-items: center;
}
