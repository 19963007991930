@import '../../../../assets/scss/color';

.util-bar {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	> .util-bar__item:not(:first-child) {
		margin-left: 0.75rem;
	}
	> .util-bar__item {
		cursor: pointer;
		> svg {
			width: 1.5rem;
			height: 1.5rem;
		}
		&:hover {
			color: $orange;
		}
	}
	& .icon-sync-status {
		margin-right: 4px;
	}
	&__sync-data {
		margin-right: 12px;
		display: flex;
		align-items: center;
		border-right: 2px solid #26262690;
		padding-right: 24px;
		&--month-picker {
			margin-left: 12px;
		}
		&--sync-icon {
			font-size: 25px;
			margin-left: 12px;
		}
	}
}
