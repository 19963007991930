@import '../../assets/scss/color';
@import 'color';

.setting_dropdown {
	padding: 0.5rem 1rem 1.5rem 1rem;
}
.userName_company {
	margin-left: 2rem;
}
.add-link-users {
	margin-left: 2rem;
}
@media only screen and (max-width: 560px) {
	.setting_dropdown {
		padding: 0;
		margin-top: 0.5rem;
	}
	.userName_company {
		margin-top: 1rem;
		margin-left: 0;
	}
	.add-link-users {
		margin-left: 0rem;
		margin-top: 1rem;
	}
}
.ant-table-tbody > tr.ant-table-row-selected > td {
	background: #faa6149e;
	border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
	background: #faa6149e;
}
.disable-btn {
	border-color: #ee1515e3;
}
.disable-btn:hover {
	border-color: #ee1515e3 !important;
	color: #ee1515e3 !important;
}
.disable-btn:active {
	border-color: #ee1515e3 !important;
	color: #ee1515e3 !important;
}
.disable-btn:focus {
	border-color: #ee1515e3 !important;
	color: #ee1515e3 !important;
}
