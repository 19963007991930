@import url('https://fonts.googleapis.com/css2?family=Inter&family=Merriweather:ital,wght@0,400;1,700&display=swap');

.historyTable {
	&.table-row-odd {
		background-color: #ffffff;
	}
	&.table-row-even {
		background-color: #f3fbff;
	}
	.text-blue {
		color: blue;
		font-weight: bold;
	}
	& tr:nth-child(odd) {
		background-color: #ffffff;
	}
	& tr:nth-child(even) {
		background-color: #f3fbff;
	}
	& tr:hover td {
		background-color: #d9ebf7 !important;
	}
	.warning {
		background-color: #ffccc7 !important;
	}
}
.header-table{
	display: flex;
	flex-direction: column;
	h3{
		margin: 0;
	}
}
