.login {
	padding-top: 130px;
	@media (max-width: 768px) {
		padding-top: 50px;
	}
	@media screen and (max-width: 992px) {
		&--left {
			display: none;
		}
	}
	&--left {
		img {
			width: 100%;
		}
	}
	&--right {
		text-align: center;
		margin: auto 0;
		.ant-form-item {
			margin-bottom: 0px;
		}
		.ant-form-item-label > label::after {
			content: '' !important;
		}
		&__header {
			img {
				margin-bottom: 10px;
			}
			p {
				color: #3c4f8c;
				font-weight: 400;
				font-size: 21px;
				line-height: 22px;
				font-style: normal;
				font-family: 'Roboto';
				margin: 10px 0;
			}
		}
		&__content {
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
			padding: 40px 23px 51px;

			.ant-form-item {
				flex-direction: column;
				&-label {
					label {
						float: left;
					}
				}
				button {
					min-width: 100%;
				}
				&-explain-error {
					float: left;
				}
			}
		}
	}
}
