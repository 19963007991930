@import 'color';

.statistic__card {
	max-width: 340px;
	width: 340px;
	padding: 23px 20px;
	background: #fff;
	&__title {
		display: flex;
		justify-content: space-between;
		&--text {
			font-size: 20px;
			font-weight: 400;
		}
		&--count {
			font-size: 30px;
			font-weight: 700;
		}
	}

	&__img {
		display: flex;
		justify-content: center;
		margin-top: 36px;
	}
}

.w-100 {
	width: 100%;
}
