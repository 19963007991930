button {
	padding: 10px;
	background-color: #f18a0c;
	color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	&:hover {
		background-color: #fda830;
		cursor: pointer;
	}
}

.wysiwyg {
	& .wysiwyg-btn {
		margin-top: 20px;
		margin-bottom: 40px;
		text-align: center;
	}

	& .wysiwyg-preview {
		text-align: center;
	}

	& .wysiwyg-content {
		margin-top: 50px;
	}
}
