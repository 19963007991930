@import 'color';

// #root > .ant-layout.ant-layout-has-sider {
// 	height: 100vh !important;
// }

.site-layout .site-layout-background {
	background: $white;
}
.ant-layout-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ant-layout.site-layout {
	min-height: 100%;
}

.ant-layout-content.main-content {
	background-color: whitesmoke;
	padding: 1.5rem;
}

.ant-layout-sider {
	.logo {
		height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
		h1 {
			color: $white;
			margin-bottom: 0;
			margin-left: 10px;
		}
	}
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: transparent;
}
.user {
	display: flex;
	align-items: center;
	&__logout {
		margin-left: 5px;
		span {
			font-size: 14px;
			color: #000;
		}
	}
}
.ant-input-search {
	.ant-input-group {
		display: unset;
		input.ant-input {
			margin-right: 5px;
			border-radius: 0.75rem;
			width: 220px;
		}
		.ant-input-group-addon button {
			border-color: transparent;
			svg {
				font-size: 14px;
			}
		}
	}
}
.modal {
	top: unset;
	width: 567px;
	.ant-btn {
		span {
			font-size: 14px;
		}
	}
}
.ant-tabs {
	.ant-tabs-tab{
		padding: 12px
	}
	.ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
		font-size: 16px;
	}
}
.ant-tabs-tab+.ant-tabs-tab{
	margin: 0;
}
.ant-input-search-button {
	padding: 0 10px;
	border-radius: 0.75rem !important;
}
.ant-page-header-heading-sub-title {
	display: flex;
	align-items: center;
	gap: 14px;
}
.ant-form-item {
	margin-bottom: 15px;
}
.ant-tabs{
.ant-tabs-nav{
	flex-wrap: wrap;
	width: 100%;

	.ant-form-inline{
		.ant-form-item{
			padding: 4px 0px;
		}
	}
}
}
.ant-tabs-extra-content{
	flex: auto;
	flex-wra: wrap;
	// .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after{
	// 	opacity: 0;
	// }
}
.ant-tabs-nav-list{
	transform: translate(0px, 0px) !important;
}
.ant-tag{
	white-space: normal ;
}
